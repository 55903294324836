<template>
  <article :class="['news-card cart-type-aquarist rounded-xxl w-full sm:w-74 bg-light overflow-hidden transition-all duration-200 hover:shadow transform hover:-translate-y-0.5 border border-primary-light hover:border-primary-light-hover', className, `news-card-${variant.id}`]">
    <div class="relative flex items-center justify-center cursor-pointer w-auto h-50">
      <span class="sr-only">{{ $t('text-product-image') }}</span>
      <a
        :href="translatePath(ROUTES.CODE.NEWS, slugify(variant.id, variant.name))"
        class="block overflow-hidden absolute top-0 left-0 bottom-0 right-0 box-border m-0 text-light bg-radial-gradient"
        :aria-label="variant.name"
        @click.prevent="handleContentQuickView"
      >
        <Image
          :src="variant?.image?.url || _.get(variant, 'images.0.url', null) || siteSettings.product.placeholderImage"
          :alt="variant.name"
          :title="variant.name"
          layout="fill"
          object-fit="cover"
          :width="294"
          :height="200"
          sizes="294px"
          class-name="news-image"
          :loading="loading"
        />
      </a>
      <div class="absolute top-0 pt-2.5 px-5 w-full flex justify-end">
        <NewsFavorite v-if="_.has(variant, 'isInCustomerFavorites')" :variant="variant" :accent="true" class="absolute top-2.5 end-5" />
      </div>
      <div v-if="_.has(variant, 'isInCustomerLikes')" class="absolute bottom-4 start-5 flex flex-row items-center justify-start space-x-2.5">
        <ContentViews :variant="variant" :accent="true" />
        <ContentLikes :variant="variant" :accent="true" />
      </div>
    </div>
    <header class="flex flex-col justify-between px-5 pt-4 pb-5 h-69">
      <div class="flex flex-col justify-start">
        <a
          :href="translatePath(ROUTES.CODE.NEWS, slugify(variant.id, variant.name))"
          :aria-label="variant.name"
          @click.prevent="handleContentQuickView"
        >
          <h3 class="line-clamp-3 leading-tight text-lg text-primary-dark font-bold overflow-ellipsis overflow-hidden cursor-pointer mb-1 hover:primary-dark-hover hover:underline focus:primary-dark-hover focus:underline" :title="variant.name" v-text="variant.name" />
        </a>
        <div class="inline-flex items-center justify-start mb-2 overflow-hidden">
          <Link
            v-if="variant?.supplier"
            :href="translatePath(ROUTES.CODE.SUPPLIER, slugify(variant?.supplier?.id, variant?.supplier?.name))"
            :aria-label="variant?.supplier?.name"
            class="text-primary-dark font-bold text-xs hover:underline cursor-pointer whitespace-nowrap"
          >
              {{ variant?.supplier?.name || '' }}
          </Link>
          <div v-if="variant?.publishedAt" class="w-full text-xs text-primary-dark font-normal truncate">
            , {{ $luxon.fromISO(variant?.publishedAt).setLocale(routeLang.code.value).toRelative() }}
          </div>
        </div>
        <div v-if="localized(variant, 'perex')" class="w-full my-2 text-xs text-body font-normal line-clamp-5">
          {{ localized(variant, 'perex') }}
        </div>
      </div>
      <div class="w-full flex justify-end mt-3">
        <Button size="small" :on-click="handleContentQuickView">
          <span class="text-xs px-6">{{ $t('text-more') }}</span>
        </Button>
      </div>
    </header>
  </article>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';
import Image from "@components/ui/image";
import NewsFavorite from "@components/news/news-details/news-favorite";
import ContentViews from "@components/content/content-details/content-views";
import ContentLikes from "@components/content/content-details/content-likes";
import Link from "@components/ui/link/link";
import Button from "@components/ui/button";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  className: {
    type: String,
    required: false,
    default: () => ''
  },
  loading: {
    type: String,
    default: null
  }
})

const store = useSelectedStore();
const ui = useUIStore();
const { $eventBus, $luxon } = useNuxtApp();
const routeLang = useRouteLang();

const handleContentQuickView = async (action = null) => {
  if (ui.displayModal) {
    $eventBus.emit('modal:close');
  }
  nextTick(async () => {
    store.setAction(action);
    store.setContent(props.variant);
    $eventBus.emit('modal:open', 'NEWS_DETAILS');
  });
};

</script>
